// import logo from './logo3_7.svg';
import { useEffect, useState } from "react";
import "./App.css";
import Showcase from "./components/Showcase";

function App() {
  const [showcaseJson, setShowcaseJson] = useState({ data: [] });
  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "projects.json")
      .then((response) => response.json())
      .then((data) => {
        setShowcaseJson(data);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src="logos/logo.svg" className="App-logo" alt="logo" />
        <span className="App-title">{showcaseJson.title}</span>
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <Showcase />
      <footer className="App-footer">
        <span className="copyright-stack">
          © Endder 2021. Checkout{" "}
          {/* <span onClick={() => window.open("http://www.endder.com")}>
            endder.com
          </span> */}
          <a
            href="http://www.endder.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            endder.com
          </a>
        </span>
      </footer>

    </div>
  );
}

export default App;
