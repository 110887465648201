import { useEffect, useState } from "react";
// import data from '/public/projects.json';
// import showcaseJson from '../services/Showcase.json';
import "./Showcase.css";

// var jsonData = {};
// const getData = () => {
//     fetch('projects.json').then(response => {
//         console.log(response);
//         // jsonData = response.json();
//         return response.json();
//     })
// }

// const Showcase = () => {
//     useEffect(() => {
//         getData()
//     }, [])
//     return (
//         <div>
//             <h1>Showcase</h1>
//             <p>{jsonData.title}</p>
//             <p>{getData}</p>
//         </div>

//     )
// }

// export default Showcase;
// console.log(showcaseJson);

export default function Showcase(props) {
  const [showcaseJson, setShowcaseJson] = useState({ data: [] });
  // let showcaseJson = {
  //     data: []
  // };
  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "projects.json")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // return data;
        setShowcaseJson(data);
      });
    // {
    //     console.log(response.json());
    //     return response.json()
    // })
  }, []);
  // console.log(showcaseJson);

  return (
    <div>
      {/* <p className="page-title">{showcaseJson.title}</p> */}
      <div className="showcase-container">
        {showcaseJson.data.map((el, index) => {
          return (
            <div
              className="project-card"
              key={el.name + index}
              onClick={() => (window.location.href = `${el.url}`)}
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      className="flip-img"
                      src={process.env.PUBLIC_URL + "images/" + el.imgName}
                    ></img>
                  </div>
                  <div
                    className="flip-card-back"
                    style={{ backgroundColor: `${el.fill}` }}
                  >
                    <div className="flip-card-back-content">
                      <h1 className="flip-name">{el.title}</h1>
                      <p className="flip-desc">{el.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
              <span className="project-title">{el.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
